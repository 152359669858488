<template>
    <div class="common-form-btn">
        <slot name="formSubmitBtn"></slot>
    </div>
</template>
<script>
export default {
    name:"FormSubmitBtn"
}
</script>
<style lang="scss" scoped>
</style>
