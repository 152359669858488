<template>
    <div>
        <el-col :span="data.fn ? 20 : 24">
            <el-select v-model="data.value" :clearable="typeof data.clearable === 'undefined' ? true : data.clearable" :filterable="data.multiple ? false : true" :multiple="data.multiple" :collapse-tags="data.collapseTags" :placeholder="data.placeholder" :style="{'width': data.width || '100%'}"
             @clear="handelSelectClear(data)" @change="changeSel(data)" @remove-tag="removeTag">
        <el-option :label="item.label" :value="item.value" v-for="(item, index) of data.list" :key="index"></el-option>
      </el-select>
        </el-col>
        <template v-if="data.fn">
            <div v-for="(item, index) of data.fn" :key="index">
                <el-col :span="4">
                    <ButtonSub v-if="item.type == 'button'" :data="item" :parent="data" />
                </el-col>
            </div>
        </template>
    </div>

</template>
<script>
    import ButtonSub from './Sub/Button.vue';
    import TextSub from './Sub/Text.vue';
    export default {
        name: 'Select',
        components: {
            ButtonSub,
            TextSub
        },
        props: {
            data: {
                type: Object
            }
        },
        methods: {
            changeSel (data) {
                this.$emit('changeSel', data);
                // this.$eventDispatch('changeSel', data);
            },
            handelSelectClear(data) {
                this.$eventDispatch("handelSelectClear", data);
            },
            removeTag(item) {
                this.$eventDispatch("handelRemoveTag", item);
            },
        }
    }
</script>
<style scoped>
.el-select-dropdown__item.hover, .el-select-dropdown__item:hover {
    background-color: #f0f0f0;
}
</style>
