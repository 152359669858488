<template>
    <div :id="id" class="form-main-title" :style="{width:titleWidth+'px'}">
        <div class="line"></div>
        <div class="title">{{ title }}</div>
    </div>
</template>
<script>
import { number } from 'echarts';

export default {
    name: "FormMainTitle",
    props: {
        id: String,
        title: String,
        titleWidth:{
            type:String,
            default:'875.5'
        }

    }
}
</script>
<style lang="scss">
    .form-main-title {
        width: 875.5px;
        height: 38px;
        display: flex;
        align-items: center;
        background: #F7F8FA;
        .line {
            width: 3px;
            height: 14px;
            background: #3C7FFF;
            border-radius: 2px;
            margin-left: 23px;
            margin-right: 6px;
        }
        .title {
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #2B2F33;
        }
    }
</style>
