<template>
    <el-checkbox v-model="checked" @change="subItemCheckBox(checked)">{{ data.label }}</el-checkbox>
</template>
<script>
    export default {
        name: "SubCheckBox",
        props: {
            data: {
                type: Object
            },
            parent: {
                type: Object
            }
        },
        data () {
            return {
                checked: true
            }
        },
        watch: {
            'data.value' : {
                handler (val) {
                    this.initCheckVal(val)
                },
                deep: true,
                immediate: true
            },
            
        },
        methods: {
            initCheckVal (val) {
                if (val == '1') {
                     this.checked = true;
                } else if (val == '2') {
                    this.checked = false;
                }
            },
            subItemCheckBox (data) {
                this.$eventBus.$emit('subItemCheckBox', {data: data, parent: this.parent})
            }
        }
    }
</script>