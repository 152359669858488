
<template>
    <div>
        <el-col :span="data.fn ? 20 : 24">
            <el-select 
                v-model="data.value" 
                :clearable="typeof data.clearable === 'undefined' ? true : data.clearable" 
                :filterable="data.filterable" 
                :multiple="data.multiple" 
                :collapse-tags="data.collapseTags" 
                :placeholder="data.placeholder" 
                :style="{'width': data.width || '100%'}"
                @clear="handelSelectClear(data)" 
                @change="changeSelX(data)" 
                @remove-tag="removeTag"
            >
            <el-checkbox v-if="data.selectAll" class="selectAll" v-model="data.selectAllChecked" @change='selectAll'>{{data.selectAllPlaceholder || '全部'}}</el-checkbox>
        <el-option :label="item.label" :value="item.value" v-for="(item, index) of data.list" :key="index"></el-option>
      </el-select>
        </el-col>
        <template v-if="data.fn">
            <div v-for="(item, index) of data.fn" :key="index">
                <el-col :span="4">
                    <ButtonSub v-if="item.type == 'button'" :data="item" :parent="data" />
                </el-col>
            </div>
        </template>
    </div>

</template>
<script>
    import ButtonSub from './Sub/Button.vue';
    import TextSub from './Sub/Text.vue';
    export default {
        name: 'SelectX',
        components: {
            ButtonSub,
            TextSub
        },
        props: {
            data: {
                type: Object
            }
        },
        methods: {
            changeSelX (data) {
                this.$emit('changeSelX', data);
                let allValue = this.data.list.map(item => item.value);
                if(allValue.length == this.data.value.length){
                    this.data.selectAllChecked = true;
                }else{
                    this.data.selectAllChecked = false;
                }
                // this.$eventDispatch('changeSel', data);
            },
            handelSelectClear(data) {
                this.$eventDispatch("handelSelectClear", data);
            },
            removeTag(item) {
                this.$eventDispatch("handelRemoveTag", item);
            },
            selectAll(val){
                if(val){
                    this.data.value = this.data.list.map(item => item.value);
                }else{
                    this.data.value = [];
                }
                this.$emit('changeSelX', this.data);
            }
        }
    }
</script>
<style lang="scss" scoped>
.el-select-dropdown__item.hover, .el-select-dropdown__item:hover {
    background-color: #f0f0f0;
}
.selectAll {
    text-align: right;
    width: 95%;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    ::v-deep .el-checkbox__input {
        margin-left: 5px;
    }
}
::v-deep .el-select__tags {
    .el-tag  {
        max-width: 60px;
    }
}
</style>
