<template>
    <div class="sub-text">
       <p :style="{ 'color': data.color, 'font-size': '12px'}">{{ data.label }}</p>
    </div>
</template>
<script>
    export default {
        name: 'SubText',
        props: {
            data: {
                type: Object
            }
        }
    }
</script>
<style scoped>
    .sub-text {
        overflow: hidden;
    }
</style>