<template>
  <el-select ref="elSelect" :disabled="data.disabled" filterable clearable v-model="data.value"
             @visible-change="visibleChange" :placeholder="data.placeholder" :filter-method="change"
             :style="{'width': data.width}" @clear="handleClear">
    <el-option :value="data.value" label="">
      <el-tree
        node-key="id"
        ref="treeRef"
        :data="data.list"
        :props="data.defaultProps"
        :filter-node-method="filterNode"
        @node-click="handleClick"
        :default-expand-all="data.expandAll"
        class="select-tree"
      />
    </el-option>
  </el-select>
</template>

<script>
import { debounce } from '../libs/fnProcessing'

export default {
  props: {
    data: {
      type: Object
    }
  },
  methods: {
    visibleChange (data) {
      if (data) {
        this.change()
      }
    },
    handleClear () {
      this.$eventDispatch('treeClear', this.data)
    },
    handleClick (data) {
      this.$eventDispatch('treeClick', {
        data: data,
        dt: this.data,
        selRef: this.$refs.elSelect
      })
    },
    filterNode (value, data) {
      if (!value) return true
      return data[this.data.defaultProps.label].indexOf(value) !== -1
    },
    change: debounce(function (val) {
      this.$refs.treeRef.filter(val)
    }, 200)
  }
}
</script>

<style scoped>
.select-tree {
  width: calc(100% + 40px);
  margin-left: -20px;
}

.el-scrollbar .el-scrollbar__view .el-select-dropdown__item {
  height: 255px;
  overflow-y: auto;
  overflow-x: hidden;
}

.el-select-dropdown__item.selected {
  font-weight: normal;
}

ul li >>> .el-tree .el-tree-node__content {
  height: auto;
  padding: 0 20px;
}

.el-tree-node__label {
  font-weight: normal;
}

.el-tree >>> .is-current .el-tree-node__label {
  color: #409EFF;
}

.el-tree >>> .is-current .el-tree-node__children .el-tree-node__label {
  color: #606266;
  font-weight: normal;
}
</style>
