<template>
    <div :style="parent.style ? parent.style : {'float': 'rigth', 'margin-left': '5px'}">
        <el-button type="text" :style="{'color': data.color, 'font-size': data.size}" @click="subClick(data)">{{ data.label }}</el-button>
    </div>
</template>

<script>
    export default {
        name: "SubButton",
        props: {
            data: {
                type: Object
            },
            parent: {
                type: Object
            }
        },
        methods: {
            subClick (data) {
                this.$eventBus.$emit('subItemClick', {data: data, parent: this.parent});
            }
        }
    }
</script>
